import { Card, Text, Metric, Flex } from '@tremor/react'
import moment from 'moment';
import { useQuery } from '@apollo/client';
import { GET_SCRAP_QUANTITIES } from '../../services/registros/registroService';
import React from 'react'

const CardGridMap = () => {
    const friendlyNames = {
        aceite: 'Aceite',
        basura: 'Basura',
        agua: 'Agua',
        lodo: 'Lodo',
        porrones_tinas: 'Porrones/Tinas',
        filtro_de_aire: 'Filtro de Aire'
      };
      
    const colors = ['rose', 'stone', 'purple', 'yellow', 'blue'];

    const { loading, error, data, refetch } = useQuery(GET_SCRAP_QUANTITIES, {
        variables: { startDate: moment().startOf('year').unix(), endDate: moment().endOf('day').unix() },
      });

  return (
    <div className='flex gap-2 md:w-1/3'>
        {data?.getQuantityCalculations?.byScrap.map ((scrap, index) =>(
            <Card className='flex p-2 gap-2 items-center justify-between static' decoration="top" decorationColor={colors[index % colors.length]}>
                <h1 >{friendlyNames[scrap._id]}</h1>
                <div className='flex gap-5 items-end'>
                    {scrap._id === 'basura' || scrap._id === 'lodo' ? (
                        <Metric className='flex items-center gap-1'>
                            {scrap.total.toLocaleString('en-US')} <Text>Kilos</Text>
                        </Metric>
                    ) : scrap._id === 'porrones_tinas' || scrap._id === 'filtro_de_aire' ? (
                        <Metric className='flex items-center gap-1'>
                            {scrap.total.toLocaleString('en-US')} <Text>Piezas</Text>
                        </Metric>
                    ) : (
                        <Metric className='flex items-center gap-1'>
                            {scrap.total.toLocaleString('en-US')} <Text>Litros</Text>
                        </Metric>
                    )}
                </div>
            </Card>
        ))}
    </div>
  )
}

export default CardGridMap
