import React, {useEffect, useState} from 'react'
import { Tab, TabGroup, TabList, TabPanel, TabPanels, Card, } from '@tremor/react';
import { es } from 'date-fns/locale'
import { useQuery } from '@apollo/client';
import moment from 'moment/moment';
import { GET_CALCULATIONS_BY } from '../../services/tablero/TableroService';
import TableBy from './TableBy';
import TableByType from './TableByType';
import MonthYearPicker from '../shared/MonthYearPicker';
import TableByCompanies from './TableByCompanies';
import TableByBills from './TableByBills';
const DashboardBase = () => {
  const currentMonth = new Date().getMonth();
  const [month, setSelectedMonth] = useState( currentMonth );

  const { data: dataBy, loading: loadingByMonth, refetch } = useQuery(GET_CALCULATIONS_BY, {
    variables: { month },
  });

  useEffect(() => {
    if (month) {
      refetch({ month });
    }
  }, [month, refetch]);

  if (loadingByMonth) return <p>Cargando datos...</p>;

  const handleMonthChange = (month) => {
    setSelectedMonth(month);
  };

  /* const { data: dataCompany, loading: loadingCompany } = useQuery(GET_CALCULATIONS_COMPANY, {
    variables: { startDate, endDate },
  }); */
  /* const { data: dataScraps} = useQuery(GET_CALCULATIONS_SCRAPS, {
    variables: { startDate: moment().startOf('day').unix(),
      endDate: moment().endOf('day').unix() },
  }); */
 /*  const { data: dataType, loading: loadingType } = useQuery(GET_CALCULATIONS_TYPE, {
    variables: { startDate, endDate },
  }); */

  const scraps = [...new Set(dataBy?.getCalculations?.byScrap.map(entry => entry.scrap))];
  const companies = [...new Set(dataBy?.getCalculations?.byCompany.map(companies => companies.scrap))];
  const weekly = [...new Set(dataBy?.getCalculations?.byType.map(weekly => weekly.week))];
  /* const billsWeekly = [...new Set(dataBy?.getCalculations?.byBillType.map(weekly => weekly.week))]; */
  const bills = [...new Set(dataBy?.getCalculations?.byBillType.map(bill => bill.tipo))];

  const totalMontoByBills = dataBy?.getCalculations?.byBillType.reduce((total, item) => total + item.monto, 0);

  const byScrap = scraps.reduce((acc, scrap) => {
    acc[scrap] = dataBy?.getCalculations?.byScrap.filter(entry => entry.scrap === scrap);
    return acc;
    }, {});

  const byCompany = companies.reduce((acc, scrap) => {
    acc[scrap] = dataBy?.getCalculations?.byCompany.filter(companies => companies.scrap === scrap);
    return acc;
    }, {});
  
  const byWeek = weekly.reduce((acc, week) => {
    acc[week] = dataBy?.getCalculations?.byType.filter(weekly => weekly.week === week);
    return acc;
    }, {});
  
  const byBillType = bills.reduce((acc, tipo) => {
    acc[tipo] = dataBy?.getCalculations?.byBillType.filter(bill => bill.tipo === tipo);
    return acc;
    }, {});

  return (
        <div className='flex flex-col gap-5 mt-4 mx-6 md:mx-10 md:w-auto'>
          <TableByType dataBy={byWeek} totalByBills={totalMontoByBills} dataBills={byBillType}/> 
          <Card className='p-3 w-full md:hidden'>
            <MonthYearPicker onMonthChange={handleMonthChange} initialMonth={month} />
          </Card>
          <TabGroup>
            <div className='flex justify-between items-center max-w-full overflow-x-auto'>
              <div className='max-w-full overflow-x-auto'>
                <TabList>
                  <Tab>Por Residuos</Tab>
                  <Tab>Por Compañias</Tab>
                  <Tab>Por Gastos fijos</Tab>
                </TabList>
              </div>
              <Card className='w-1/7 p-3 justify-end hidden md:flex'>
                <MonthYearPicker onMonthChange={handleMonthChange} initialMonth={month} />
              </Card>
            </div>
            <TabPanels>
              <TabPanel>
                <TableBy dataBy={byScrap}/>
              </TabPanel>
              <TabPanel>
                <TableByCompanies dataBy={byCompany}/>      
              </TabPanel>
              <TabPanel>
                <TableByBills dataBy={byBillType}/>      
              </TabPanel>
            </TabPanels>
          </TabGroup>
        </div>
  )
}

export default DashboardBase
