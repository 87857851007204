import { BadgeDelta, Card, List, ListItem, Table, TableBody, TableCell, TableHead, TableHeaderCell, TableRow, Text } from '@tremor/react';
import React from 'react'
import MonthYearPicker from '../shared/MonthYearPicker';
import CardGridMap from './CardGridMap';

const TableByType = ({ dataBy, totalByBills, dataBills }) => {

const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

const calculateTotals = () => {
    const totals = {};
    Object.keys(dataBy).forEach(week => {
      const entries = dataBy[week].map(item => item.entry).reduce((a, b) => a + b, 0);
      const exits = dataBy[week].map(item => item.exit).reduce((a, b) => a + b, 0);

      let exitsWithMontos = exits;
        Object.keys(dataBills).forEach(tipo => {
            dataBills[tipo].forEach(item => {
            if (item.week === week) {
                exitsWithMontos += item.monto;
            }
            });
        });

      totals[week] = { totalEntry: entries, totalExit: exitsWithMontos };
    });
    return totals;
};
  
const totals = calculateTotals();

const calculateGlobalTotals = (totals) => {
    let globalTotalEntry = 0;
    let globalTotalExit = 0;
  
    Object.values(totals).forEach(weekData => {
      globalTotalEntry += weekData.totalEntry;
      globalTotalExit += weekData.totalExit;
    });

    globalTotalExit -= totalByBills;
  
    return { globalTotalEntry, globalTotalExit };
  };
  
const globalTotals = calculateGlobalTotals(totals);

  return (
    <div className='flex flex-col gap-5'>
        <Card>
            <List>
                <ListItem>
                    <span className='font-medium text-black'>Total de ingresos</span>
                    <BadgeDelta deltaType="increase" isIncreasePositive={true}>
                        <span>{globalTotals.globalTotalEntry.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span>
                    </BadgeDelta>
                </ListItem>
                <ListItem>
                    <span className='font-medium text-black'>Total de gastos</span>
                    <BadgeDelta deltaType="decrease" isIncreasePositive={true}>
                        <span>{globalTotals.globalTotalExit.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span>
                    </BadgeDelta>
                </ListItem>
            </List>
        </Card>
        <div className="max-w-full overflow-x-auto flex gap-4 py-4">
            <React.Fragment>
                {Object.keys(dataBy).map(week => (
                    <Card className="min-w-[300px] md:min-w-0 p-4" key={week}>
                        <div className='flex justify-between'>
                            <h3 className="font-medium">{capitalizeFirstLetter(week)}</h3>
                        </div>
                        <List>
                            <ListItem>
                                <Text>Total ingresos</Text>
                                <BadgeDelta deltaType="increase" isIncreasePositive={true}>
                                    {totals[week].totalEntry.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                                </BadgeDelta>
                            </ListItem>
                            <ListItem className=' mb-3'>
                                <Text>Total gastos</Text>
                                <BadgeDelta deltaType="decrease" isIncreasePositive={true}>
                                    {totals[week].totalExit.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                                </BadgeDelta>
                            </ListItem>
                            <ListItem className='flex justify-between'>
                                <Text>Total:</Text>
                                <Text className='font-medium'>{(totals[week].totalEntry + totals[week].totalExit).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</Text>
                            </ListItem>
                        </List>
                    </Card>
                ))}
            </React.Fragment>
        </div>
      <CardGridMap/>
    </div>
  )
}

export default TableByType
