import React from 'react'
import { NavLink } from '../sidebar/NavLink'
import logoCIBA from '../../assets/Logo-w.png'
import { useAuth0 } from '@auth0/auth0-react';
import { RiBankLine, RiBuilding4Line, RiCalendar2Line, RiEdit2Line, RiLogoutBoxLine, RiPieChart2Line, RiStackLine, RiUser3Line, RiWallet3Line } from '@remixicon/react';
import { Button, Icon } from '@tremor/react';
import { useQuery } from '@apollo/client';
import { GET_USER_LIST } from '../../services/usuarios/userServices';

const SideBar = () => {
  const {
    isAuthenticated,
    logout,
    user
  } = useAuth0();  

  const { loading, error, data } = useQuery(GET_USER_LIST);
  const users = data?.listUsers?.users;
  const emailToFind = user?.email;

  const findUserByEmail = (users, email) => {
    return users?.find(user => user.email === email) || null;
  };

  const foundUser = findUserByEmail(users, emailToFind);

  return (
    <div className="flex flex-col w-full md:w-1/6 md:h-screen md:top-0 bg-primary-blue md:sticky">
      {/* Header / Sidebar Content */}
      <div className="flex flex-row md:flex-col md:w-full justify-between md:justify-center items-center md:bg-secondary-blue rounded-b-xl">
        <img src={logoCIBA} alt="logo" className="object-contain h-16 w-36 mx-3 md:h-24 md:w-40 md:m-5" />
        <hr className="hidden md:block w-4/5" />
        <div className="flex gap-3 md:gap-5 items-center my-3 md:m-5">
          <div className="flex md:gap-5 items-center text-white">
          <Icon icon={RiUser3Line} variant="simple" size="xl" className="w-8 h-8 text-primary-green md:w-10 md:h-10 hidden md:block" />
            <div className='flex justify-center items-center'>
              <div className='flex flex-col'>
                <p className='text-sm md:text-base'>{user.nickname}</p>
                <p className='text-sm md:text-base hidden md:block'><b>{foundUser?.role?.charAt(0).toUpperCase() + foundUser?.role?.slice(1)}</b></p>
              </div>
              <Icon icon={RiUser3Line} variant="simple" size="sm" className="w-8 h-8 text-primary-green md:w-10 md:h-10 block md:hidden" />
            </div>
            <div className="flex md:hidden">
              {isAuthenticated && (
                <button className="text-white text-sm md:text-base hover:text-slate-300 bg-transparent"
                onClick={() => logout({ returnTo: window.location.origin })}
                >
                  Cerrar sesión
                </button>
              )}
              <Icon icon={RiLogoutBoxLine} variant="simple" size="sm" className="w-8 h-8 text-primary-green md:w-10 md:h-10" />
            </div>
          </div>
        </div>
      </div>

      <nav className="hidden md:block md:w-full md:mt-5">
        <ul className="flex flex-col md:w-full gap-5">
          {/* Enlaces visibles para todos los usuarios */}
          <li>
            <NavLink to='/'>
              <Icon icon={RiEdit2Line} variant="simple" size="lg" className="text-white"/>
              <p className='flex justify-center w-1/2'>Registro</p>
            </NavLink>
          </li>
          <li>
            <NavLink to='/manifiestos'>
              <Icon icon={RiStackLine} variant="simple" size="lg" className="text-white"/>
              <p className='flex justify-center w-1/2'>Manifiestos</p>
            </NavLink>
          </li>

          {foundUser?.role === 'admin' && (
            <>
              <li>
                <NavLink to='/finanzas'>
                  <Icon icon={RiBankLine} variant="simple" size="lg" className="text-white"/>
                  <p className='flex justify-center w-1/2'>Finanzas</p>
                </NavLink>
              </li>
              <li>
                <NavLink to='/tablero'>
                  <Icon icon={RiPieChart2Line} variant="simple" size="lg" className="text-white"/>
                  <p className='flex justify-center w-1/2'>Tablero</p>
                </NavLink>
              </li>
              <li>
                <NavLink to='/gastosfijos'>
                  <Icon icon={RiWallet3Line} variant="simple" size="lg" className="text-white"/>
                  <p className='flex justify-center w-1/2'>Gastos Fijos</p>
                </NavLink>
              </li>
              <li>
                <NavLink to='/usuarios'>
                  <Icon icon={RiUser3Line} variant="simple" size="lg" className="text-white"/>
                  <p className='flex justify-center w-1/2'>Usuarios</p>
                </NavLink>
              </li>
              <li>
                <NavLink to='/empresas'>
                  <Icon icon={RiBuilding4Line} variant="simple" size="lg" className="text-white"/>
                  <p className='flex justify-center w-1/2'>Empresas</p>
                </NavLink>
              </li>
              <li>
                <NavLink to='/calendario'>
                  <Icon icon={RiCalendar2Line} variant="simple" size="lg" className="text-white"/>
                  <p className='flex justify-center w-1/2'>Calendario</p>
                </NavLink>
              </li>
            </>
          )}

          
        </ul>
      </nav>

      {/* Mobile Header Navigation */}
      <nav className="flex md:hidden justify-around w-full py-3 bg-secondary-blue">
        <NavLink to='/'>
          <Icon icon={RiEdit2Line} variant="simple" size="lg" className="text-white" />
        </NavLink>
        <NavLink to='/manifiestos'>
          <Icon icon={RiStackLine} variant="simple" size="lg" className="text-white"/>
        </NavLink>
        {foundUser?.role === 'admin' && (
          <>
            <NavLink to='/finanzas'>
              <Icon icon={RiBankLine} variant="simple" size="lg" className="text-white" />
            </NavLink>
            <NavLink to='/tablero'>
              <Icon icon={RiPieChart2Line} variant="simple" size="lg" className="text-white" />
            </NavLink>
            <NavLink to='/gastosfijos'>
              <Icon icon={RiWallet3Line} variant="simple" size="lg" className="text-white" />
            </NavLink>
            <NavLink to='/usuarios'>
              <Icon icon={RiUser3Line} variant="simple" size="lg" className="text-white" />
            </NavLink>
            <NavLink to='/empresas'>
              <Icon icon={RiBuilding4Line} variant="simple" size="lg" className="text-white" />
            </NavLink>
            <NavLink to='/calendario'>
              <Icon icon={RiCalendar2Line} variant="simple" size="lg" className="text-white" />
            </NavLink>
          </>
        )}
        
      </nav>

      <div className="md:flex items-center md:items-end md:w-full justify-center gap-2 p-4 md:flex-grow hidden">
        {isAuthenticated && (
          <Button className="text-white font-bold hover:text-slate-300 bg-transparent md:mb-5"
            icon={RiLogoutBoxLine} 
            onClick={() => logout({ returnTo: window.location.origin })}
          >
            Cerrar sesión
          </Button>
        )}
      </div>
    </div>
  )
}

export default SideBar
