import {
  BadgeDelta,
  Button,
  Card,
  DateRangePicker,
  DateRangePickerItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
  Text,
  Title,
} from "@tremor/react";
import { DELETE_RECORD, GET_RECORDS, GET_SCRAP_QUANTITIES } from "../../services/registros/registroService";
import { useMutation, useQuery } from "@apollo/client";
import moment from 'moment-timezone';
import { es } from 'date-fns/locale'
import { useEffect, useState } from "react";
import { RiDeleteBin2Fill, RiRefreshLine } from "@remixicon/react";
import ConfirmationModal from "../shared/ConfirmationModal";
import Notification from "../shared/NotificationAlert";
import { GET_FINANCE, GET_FINANCE_TOTAL } from "../../services/finanzas/finanzasService";
  const HistorialRegistro = ({ reset }) => {

    const [deleteRecord, { dataUser }] = useMutation(DELETE_RECORD, {
      refetchQueries: [
        { query: GET_FINANCE }, 
        { query: GET_FINANCE_TOTAL },
        { query: GET_RECORDS },
        { query: GET_SCRAP_QUANTITIES }, 
      ]
    });

    const today = moment().startOf('day').toDate();
    const todayEnd = moment().endOf('day').toDate();

    // Define el estado inicial con la fecha de hoy
    const [value, setValue] = useState({
      from: today ,
      to: todayEnd 
    });
    
    //Notification values
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [selectedId, setSelectedId] = useState(null);
    const [title, setTitle] = useState('');
    const [showNotification, setShowNotification] = useState(false);

    const friendlyNames = {
      aceite: 'Aceite',
      basura: 'Basura',
      agua: 'Agua',
      lodo: 'Lodo',
      porrones_tinas: 'Porrones / Tinas',
      filtro_de_aire: 'Filtro de Aire'
    };

    const { loading, error, data, refetch } = useQuery(GET_RECORDS);

    const deleteRecordById = (moveId) => {
      deleteRecord({ variables: { id: moveId} })
        .then(result => {
          handleShowNotification(`El registro fue eliminado con éxito.`)
          updateDates();
        })
        .catch(error => {
          handleShowNotification(`Error al eliminar Registro`, error)
          console.error('Error al eliminar Registro:', error);
        });
    };

    // Maneja el cambio de valor en el componente de DateRangePicker
    const handleValueChange = (newValue) => {
      // Establecer el valor 'to' como la última hora del día si se ha definido un 'to'
      if (newValue.to) {
        const endOfDay = moment(newValue.to).endOf('day').toDate();
        setValue({ from: newValue.from, to: endOfDay });
      } else {
          setValue(newValue);
      }
    };

    // Maneja la actualización de las fechas
    const updateDates = () => {
      if (value.from && value.to) {
        refetch({
            startDate: moment(value.from).unix(),
            endDate: moment(value.to).unix()
        });
      }
    };

    const handleShowNotification = (message) => {
      setMessage(message)
      setShowNotification(true);
      setTimeout(() => {
        setShowNotification(false);
      }, 3000); // Oculta la notificación después de 2 segundos
    };

    const handleOpenConfirmModal = (title, message, id) => {
      setMessage(message)
      setTitle(title)
      setSelectedId(id);
      setConfirmModalOpen(true)
    };
  
    const handleCloseConfirmModal = () => {
      setConfirmModalOpen(false);
    };
  
    const handleConfirmAction = (id) => {
      deleteRecordById(id)
      handleCloseConfirmModal();
    };

    const formatDateTime = (timestamp) => {
      return moment.unix(timestamp).tz('America/Monterrey').format('DD/MM/YYYY');
    };

    useEffect(() => {
      if (reset) {
        setValue({ from: today, to: todayEnd });
        refetch({ startDate: moment(today).unix(), endDate: moment(todayEnd).unix() });
      }
    }, [reset, refetch]);
    
    if (loading) return <Card className="mx-auto w-auto m-10">Cargando</Card>;
    if (error) return <p>Error : {error.message}</p>;

    return (
      <Card>
        <ConfirmationModal
          isOpen={confirmModalOpen}
          onClose={handleCloseConfirmModal}
          message={message}
          onConfirm={handleConfirmAction}
          onCancel={handleCloseConfirmModal}
          confirmText="Aceptar"
          cancelText="Cancelar"
          title={title}
          id={selectedId}
        />
        {showNotification && <Notification message={message} />}
        <div className="flex flex-col md:flex-row gap-5 md:gap-0 md:justify-between">
          <Title className="flex-1">Registros de entrada y salida</Title>
          <div className="flex items-center space-x-2">
            <DateRangePicker
              className="mx-auto max-w-md"
              value={value}
              onValueChange={handleValueChange}
              locale={es} // Establecer el locale español
              selectPlaceholder="Seleccionar"
              placeholder="Selecciona un rango de fecha"
              maxDate={new Date()}
              enableClear={false}
              color="rose"
            >
              <DateRangePickerItem
                key="today"
                value="today"
                from={moment().startOf('day').toDate()} // Comenzar desde el principio del día actual
                to={moment().endOf('day').toDate()} // Terminar al final del día actual
              >
                Hoy
              </DateRangePickerItem>
              <DateRangePickerItem
                key="last7days"
                value="last7days"
                from={moment().subtract(7, 'days').startOf('day').toDate()} // Hace 7 días desde el principio del día actual
                to={moment().endOf('day').toDate()} // Terminar al final del día actual
              >
                Últimos 7 días
              </DateRangePickerItem>
              <DateRangePickerItem
                key="last30days"
                value="last30days"
                from={moment().subtract(30, 'days').startOf('day').toDate()} // Hace 30 días desde el principio del día actual
                to={moment().endOf('day').toDate()} // Terminar al final del día actual
              >
                Últimos 30 días
              </DateRangePickerItem>
              <DateRangePickerItem
                key="monthToDate"
                value="monthToDate"
                from={moment().startOf('month').toDate()} // Comenzar desde el principio del mes actual
                to={moment().endOf('day').toDate()} // Terminar al final del día actual
              >
                Del mes a la fecha
              </DateRangePickerItem>
              <DateRangePickerItem
                key="yearToDate"
                value="yearToDate"
                from={moment().startOf('year').toDate()} // Comenzar desde el principio del año actual
                to={moment().endOf('day').toDate()} // Terminar al final del día actual
              >
                Del año a la fecha
              </DateRangePickerItem>
            </DateRangePicker>
            <Button icon={RiRefreshLine} onClick={updateDates}></Button>
          </div>
          
        </div>
        <Table className="mt-5">
          <TableHead>
            <TableRow>
              <TableHeaderCell>Empresa</TableHeaderCell>
              <TableHeaderCell>Residuo</TableHeaderCell>
              <TableHeaderCell>Cantidad</TableHeaderCell>
              <TableHeaderCell>Fecha</TableHeaderCell>
              <TableHeaderCell>Registro</TableHeaderCell>
              <TableHeaderCell>Eliminar</TableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {data?.listRecords?.records.length === 0 ? (
            <TableRow>
              <TableCell colSpan={6} className="text-center">
                No hay registros del día de hoy.
              </TableCell>
            </TableRow>
          ) : (
            data?.listRecords?.records.map((record) => (
              <TableRow key={record._id}>
                <TableCell>{record.company.name}</TableCell>
                <TableCell>
                  <Text>{friendlyNames[record.scrap]}</Text>
                </TableCell>
                <TableCell>
                  <Text>{record.quantity.quantity} {record.quantity.type}</Text>
                </TableCell>
                <TableCell>
                  <Text>{formatDateTime(record.entry_date)}</Text>
                </TableCell>
                <TableCell>
                  {record.type === 'exit' ? <BadgeDelta deltaType="decrease">Salida</BadgeDelta> : <BadgeDelta deltaType="increase">Entrada</BadgeDelta>}
                </TableCell>
                <TableCell>
                  <Button 
                    className='bg-red-600 border-transparent hover:border-transparent hover:bg-red-700' 
                    icon={RiDeleteBin2Fill}
                    onClick={() => handleOpenConfirmModal(`¿Deseas eliminar el registro de ${record.quantity.quantity} ${record.quantity.type}?`, 'El movimiento  se eliminara permanentemente.', record._id)}
                  ></Button>
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
        </Table>
      </Card>
    )
  }

export default HistorialRegistro
